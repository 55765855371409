import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageTooltipDiv from './ImageTooltipDiv';
import './ImageTooltip.css';

class ImageTooltip extends Component {
  constructor(props) {
    super(props);
    const { config } = this.props;
    const zone0 = config.zones[0];
    this.imgContainerId = `${zone0.top + zone0.left + zone0.height + zone0.width}`;
    this.state = {
      renderTooltips: false,
    };
    this.getImageTooltipDivs = this
      .getImageTooltipDivs
      .bind(this);
    this.updateImageDimensions = this
      .updateImageDimensions
      .bind(this);
  }

  componentDidMount() {
    this.updateImageDimensions();
    this.setState({ renderTooltips: true });
    window.addEventListener('resize', this.updateImageDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateImageDimensions);
  }

  getImageTooltipDivs(config) {
    let widthRatio = 1;
    let heightRatio = 1;
    if (this.state.imgHeight && this.state.imgWidth) {
      widthRatio = config.image.width / this.state.imgWidth;
      heightRatio = config.image.height / this.state.imgHeight;
    }
    return config
      .zones
      .map(conf => (
        <ImageTooltipDiv
          key={`${conf.top * conf.left * conf.height * conf.width}`}
          top={conf.top / heightRatio}
          left={conf.left / widthRatio}
          title={conf.title}
          description={conf.description}
        />
      ));
  }

  updateImageDimensions() {
    this.setState({
      imgWidth: document
        .getElementById(this.imgContainerId)
        .clientWidth,
      imgHeight: document
        .getElementById(this.imgContainerId)
        .clientHeight,
    });
  }

  render() {
    const { children, config } = this.props;

    const style = {
      display: 'inline-block',
      position: 'relative',
    };
    return (
      <div style={style} id={this.imgContainerId}>
        {this.state.renderTooltips && this.getImageTooltipDivs(config)}
        {children}
      </div>
    );
  }
}

ImageTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes
    .shape({
      image: PropTypes
        .shape({ width: PropTypes.number.isRequired, height: PropTypes.number.isRequired })
        .isRequired,
      zones: PropTypes
        .arrayOf(PropTypes.shape({
          top: PropTypes.number.isRequired,
          left: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired)
        .isRequired,
    })
    .isRequired,
};

export default ImageTooltip;
