import React from 'react';
import { graphql } from 'gatsby';
import '../styles/project.scss';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import ButtonCircle from '../components/ButtonCircle';
import ImageTooltip from '../components/ImageTooltip/ImageTooltip';

class ProjectPage extends React.Component {
  static backButtonCallback(event) {
    if (window.history.length > 1) {
      event.preventDefault();
      window.history.back();
    }
  }

  constructor(props) {
    super(props);
    this.next = this
      .next
      .bind(this);
    this.previous = this
      .previous
      .bind(this);
    this.state = {
      sliderHeight: '100vh - (2rem + 53px - 25px + 15vh)',
    };
  }

  componentDidMount() {
    this.setState({
      sliderHeight: window.innerWidth < 1000 ? '30vh' : '100vh - (2rem + 53px - 25px + 15vh)',
    });
  }

  next() {
    this
      .slider
      .slickNext();
  }

  previous() {
    this
      .slider
      .slickPrev();
  }

  render() {
    const { data } = this.props;
    const chantier = data.datoCmsChantier;
    const {
      nom,
      description,
      photos,
      anneeDeRealisation,
      travauxRealises,
      architecte,
      localisation,
      maitreDOuvrage,
      imageTooltipConfig,
    } = chantier;
    const sliderSettings = {
      infinite: true,
      variableWidth: true,
      arrows: false,
      slidesToScroll: 1,
    };
    const tagsElements = travauxRealises.map(tag => <span key={tag.nom}>{tag.nom}</span>);
    const { sliderHeight } = this.state;
    const slidesElements = photos.map((photo, index) => {
      if (imageTooltipConfig && index < imageTooltipConfig.length && imageTooltipConfig && sliderHeight === '100vh - (2rem + 53px - 25px + 15vh)') {
        return (
          <div key={photo.id}>
            <ImageTooltip config={imageTooltipConfig[index]}>
              <Img
                fluid={photo.fluid}
                style={{
                  width: `calc((${photo.width} / ${photo.height}) * (${sliderHeight}))`,
                }}
              />
            </ImageTooltip>
          </div>
        );
      }
      return (
        <div key={photo.id}>
          <Img
            fluid={photo.fluid}
            style={{
              width: `calc((${photo.width} / ${photo.height}) * (${sliderHeight}))`,
            }}
          />
        </div>
      );
    });
    return (
      <Layout menu={false}>
        <Helmet
          title={`${nom} - Ruiz Aluminium`}
        />
        <div className="project_container">
          <div className="return_button_container">
            <ButtonCircle callback={ProjectPage.backButtonCallback} type="arrow_left" />
          </div>
          <div className="slider_container">
            <div>
              <Slider
                {...sliderSettings}
                ref={(c) => {
                  this.slider = c;
                }}
              >
                {slidesElements}
              </Slider>
            </div>

            <button type="button" onClick={this.next} />
          </div>
          <div className="project_title_container">
            <div className="project_title">
              {nom}
              &nbsp;|&nbsp;
              <strong>{anneeDeRealisation}</strong>
            </div>
          </div>
          <div className="infos_container">
            <div className="infos_left">
              <span>Année de réalisation</span>
              <span>{anneeDeRealisation}</span>
              {maitreDOuvrage !== '' && <span>Maître d'ouvrage</span>}
              {maitreDOuvrage !== '' && <span>{maitreDOuvrage}</span>}
              {architecte !== '' && <span>Architecte</span>}
              {architecte !== '' && <span>{architecte}</span>}
              {localisation !== '' && <span>Localisation</span>}
              {localisation !== '' && <span>{localisation}</span>}
            </div>
            <div className="infos_right">
              <p>{description}</p>
              <div className="tags">
                <span>Travaux réalisés</span>
                {tagsElements}
              </div>
            </div>
          </div>
        </div>
      </Layout>

    );
  }
}

ProjectPage.defaultProps = {
  data: {},
};

ProjectPage.propTypes = {
  data: PropTypes.shape({
    datoCmsChantier: PropTypes
      .shape({
        nom: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        travauxRealises: PropTypes.arrayOf(PropTypes.shape({ nom: PropTypes.string })),
      })
      .isRequired,
  }),
};

export default ProjectPage;

export const query = graphql`
  query ProjectQuery($slug: String!) {
    datoCmsChantier(slug: {eq: $slug}) {
            nom,
            description,
            anneeDeRealisation,
            travauxRealises {
              nom
            },
            imageTooltipConfig {
          image {
            width
            height
          }
          zones {
            left
            top
            description
            title
          }
        },
            maitreDOuvrage,
            architecte,
            localisation,
            photos {
              id,
              width,
              height,
              fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }){
                ...GatsbyDatoCmsFluid
              },
            }
      }
  }
`;
