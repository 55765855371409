import React from 'react';
import './ImageTooltipDiv.scss';

const ImageTooltipDiv = function statelessFunctionComponentClass(props) {
  const style = {
    top: props.top,
    left: props.left,
    // width: props.width,
    // height: props.height,
  };

  return (
    <div className="image_tooltip_div">
      <div style={style}>
        <div>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageTooltipDiv;
